<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field readonly
                clickable
                name="省级"
                :value="formMess.provinceName"
                label="省级"
                placeholder="点击选择省级"
                @click="showPicker1"
                :rules="[{ required: true, message: '请选择省级' }]" />
      <van-field readonly
                clickable
                name="市级"
                :value="formMess.cityName"
                label="市级"
                placeholder="点击选择市级"
                @click="showPicker2"
                :rules="[{ required: true, message: '请选择市级' }]" />
      <van-field readonly
                clickable
                name="县级"
                :value="formMess.districtName"
                label="县级"
                placeholder="点击选择县级"
                @click="showPicker3"
                :rules="[{ required: true, message: '请选择县级' }]" />
      <van-field readonly
                clickable
                name="乡镇"
                :value="formMess.townName"
                label="乡镇"
                placeholder="点击选择乡镇"
                @click="showPicker4"
                :rules="[{ required: true, message: '请选择乡镇' }]" />
      <van-field readonly
                clickable
                name="picker"
                :value="formMess.villageName"
                label="社区(村)"
                placeholder="点击选择社区(村)"
                @click="showPicker5"
                :rules="[{ required: true, message: '请选择社区(村)' }]" />
      <!-- 文本 -->
      <van-field v-model="formMess.fullAddress"
                name="详细地址"
                label="详细地址："
                placeholder="请填写详细地址"
                :rules="[{ required: true, message: '请填写详细地址' }]" />
      <van-field v-model="formMess.linkName"
                name="姓名"
                label="姓名："
                placeholder="请填写姓名"
                :rules="[{ pattern: /^.{1,8}$/, message: '请填写姓名' }]" />
      <van-field v-model="formMess.linkPhone"
                type="tel"
                name="电话"
                label="联系电话："
                placeholder="请填写电话"
                :rules="[{ pattern: /^(0|86|17951)?1[\d]{10}$/, message: '请填写电话' }]" />
      <van-field readonly
                clickable
                name="贮存点"
                :value="formMess.storeName"
                label="贮存点"
                placeholder="点击选择贮存点"
                @click="showPicker6"
                :rules="[{ required: true, message: '请选择贮存点' }]" />
      <!-- 开关 -->
      <van-field name="switch"
                 label="是否默认">
        <template #input>
          <van-switch v-model="formMess.isDefault"
                      size="20" />
        </template>
      </van-field>
      <div class="btn">
      <van-button round block type="info" native-type="submit">保存</van-button>
    </div>
    </van-form>
    <div class="btn" v-if="isShowbtnsc">
      <van-button round block type="danger" @click="delUserAddress">删除</van-button>
    </div>
    <div class="btn">
      <van-button round block type="default" @click="goBackClick">返回</van-button>
    </div>
    <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
        <div class="btn" style="padding: 0 30px" v-if="isShowbtnzcd">
          <van-button round block type="info" @click="addzcdClick">添加贮存点</van-button>
        </div>
      </van-popup>
      <van-dialog v-model="isShowipt" title="添加贮存点" show-cancel-button @confirm="addconfirm">
        <van-field v-model="zcdValue" label="贮存点名称" placeholder="请输入贮存点名称" />
      </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { Toast } from 'vant'
import { companystore } from '@/api/order.js'
import { address, getDivisionList, addressEdit, addressdel, jsapiSignature, storeList } from '@/api/notice.js'
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      amap_key: '3b7a88fa011cc25b116c2cd355292b1d',
      isShowipt: false,
      isShowbtnsc: false,
      isShowbtnzcd: false,
      query: {
        level: undefined,
        divisionId: undefined
      },
      columns: [],
      showPicker: false,
      formMess: {
        id: undefined, // 地址编号，有则更新，无则新增
        divisionId: undefined, // 行政区域代码
        provinceName: undefined, // 省份
        cityName: undefined, // 市
        districtName: undefined, // 地区
        townName: undefined, // 街道城镇
        villageName: undefined, // 乡村社区
        fullAddress: undefined, // 详细地址
        linkName: undefined, // 姓名
        linkPhone: undefined, // 电话
        isDefault: undefined, // 是否默认
        storeId: undefined, // 贮存点id
        storeName: undefined, // 贮存点name
        longitude: undefined,
        latitude: undefined,
      },
      editId: undefined, // 保存编辑跳转的id
      provinceId: undefined, // 保存县级id
      cityId: undefined, // 保存市级id
      districtId: undefined, // 保存区级id
      townId: undefined, // 保存镇级id
      zcdValue: undefined, // add贮存点
    }
  },
  created() {
    if (this.$route.query.editId) {
      this.isShowbtnsc = true
      this.editId = this.$route.query.editId
      this.addressEdit()
    } else {
      this.jsapiSignature()
    }
  },
  methods: {
    addressEdit() { // 编辑获取详情
      addressEdit(this.editId).then(res => {
        this.formMess = res.data.data
        if (this.formMess.isDefault === 1) {
          this.formMess.isDefault = true
        } else {
          this.formMess.isDefault = false
        }
      })
    },
    showPicker1() { // 省
      this.query.level = 1
      getDivisionList(this.query).then(res => {
        const columnss = []
        columnss.push(res.data.data.divisions.find(item => item.divisionId == '33'))
        this.columns = columnss.map(item => {
          return {
            text: item.name,
            id: item.divisionId
          }
        })
        this.showPicker = true
      })
    },
    showPicker2() { // 市
      if (this.formMess.provinceName) {
        this.query.level = 2
        this.query.divisionId = this.provinceId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker3() { // 县
      if (this.formMess.cityName) {
        this.query.level = 3
        this.query.divisionId = this.cityId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker4() { //镇
      if (this.formMess.districtName) {
        this.query.level = 4
        this.query.divisionId = this.districtId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name.replace('办事处', ''),
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker5() { // 村
      if (this.formMess.townName) {
        this.query.level = 5
        this.query.divisionId = this.townId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            item.name = item.name.replace(/村民委员会$/, '')
            item.name = item.name.replace(/村委会村委会$/, '村')
            item.name = item.name.replace(/居?委会$/, '')
            item.name = item.name.replace(/村村$/, '村')
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker6() { // 贮存点
      this.query.level = undefined
      storeList().then(res => { // 贮存点list
        if (res.data.code === 200) {
          this.columns = res.data.rows.map(item => {
            return {
              text: item.storeName,
              id: item.id
            }
          })
          this.showPicker = true
          this.isShowbtnzcd = true
        }
      })
    },
    addzcdClick() { // 添加贮存点
      this.isShowipt = true
    },
    addconfirm() { // 添加贮存点确认弹框
      companystore({storeName: this.zcdValue}).then(res => {
        if (res.data.code === 200) {
          Toast('添加成功')
          this.zcdValue = undefined
          storeList().then(res => { // 贮存点list
            if (res.data.code === 200) {
              this.columns = res.data.rows.map(item => {
                return {
                  text: item.storeName,
                  id: item.id
                }
              })
            }
          })
        }
      })
    },
    onConfirm(value) { // 弹框
      if (this.query.level == 1) {
        this.formMess.provinceName = value.text
        this.formMess.cityName = ''
        this.formMess.districtName = ''
        this.formMess.townName = ''
        this.formMess.villageName = ''
        this.provinceId = value.id
      } else if (this.query.level == 2) {
        this.formMess.cityName = value.text
        this.formMess.districtName = ''
        this.formMess.townName = ''
        this.formMess.villageName = ''
        this.cityId = value.id
      } else if (this.query.level == 3) {
        this.formMess.districtName = value.text
        this.formMess.townName = ''
        this.formMess.villageName = ''
        this.districtId = value.id
      } else if (this.query.level == 4) {
        this.formMess.townName = value.text
        this.formMess.villageName = ''
        this.townId = value.id
      } else if (this.query.level == 5) {
        this.formMess.villageName = value.text
        this.formMess.divisionId = value.id
      } else {
        this.formMess.storeId = value.id
        this.formMess.storeName = value.text
      }
      this.showPicker = false;
    },
    parameters() { // 逆地址解析
      const quancheng = this.formMess.provinceName + this.formMess.cityName + this.formMess.districtName + this.formMess.townName + this.formMess.villageName + this.formMess.fullAddress
      this.$axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/geo?parameters',
        params: {
          key: this.amap_key,
          address: quancheng,
          city: this.cityId
        }
      }).then(res => { // 解析
        if (res.data.status == 1) {
          const arr = res.data.geocodes[0].location
          this.formMess.longitude = arr.substring(0, arr.lastIndexOf(','))
          this.formMess.latitude = arr.substring(this.formMess.longitude.length + 1, arr.length)
          if (this.formMess.isDefault) {
            this.formMess.isDefault = 1
          } else {
            this.formMess.isDefault = 0
          }
          this.address()
        }
      })
    },
    address() {
      address(this.formMess).then(res => { // 提交保存
        if (res.data.code === 200) {
          if (this.formMess.isDefault === 1) {
            this.formMess.isDefault = true
          } else {
            this.formMess.isDefault = false
          }
          Toast('添加/编辑成功')
          this.$router.go(-1)
        }
      })
    },
    onSubmit() { // 提交保存
      this.parameters()
    },
    delUserAddress() { // 删除
      addressdel(this.editId).then(res => {
        if (res.data.code === 200) {
          Toast('删除成功')
          this.$router.go(-1)
        }
      })
    },
    jsapiSignature() { // 自动获取地址
      let hrefurl = sessionStorage.getItem("url")
      jsapiSignature({url: hrefurl}).then(res => {
        wx.config({
          debug: false, // 开启调试模式,
          appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名，见附录1
          jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.checkJsApi({
          jsApiList: ['getLocation'],
          success: function (res) {
            if (res.checkResult.getLocation == false) {
              alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！')
              return
            }
          }
        })
        wx.ready(() => {
          wx.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: (res) => {
              let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              let location = longitude + ',' + latitude
              this.locationToAddress(location)
            }
          })
        })
        wx.error(function(res){
          console.log(res)
        })
      })
    },
    locationToAddress (location) { // 经纬度转详细地址
      this.$axios({
        method: 'get',
        url: 'https://restapi.amap.com/v3/geocode/regeo?parameters',
        params: {
          key: this.amap_key,
          location: location
        }
      }).then(res => {
        const address = res.data.regeocode.addressComponent
        this.provinceId = address.adcode.substring(0, 2) // 省id
        this.formMess.provinceName = address.province // 省名
        this.cityId = address.adcode.substring(0, 4) // 城市id
        this.formMess.cityName = address.city // 城市名
        this.districtId = address.adcode // 区id
        this.formMess.districtName = address.district // 区名
      })
    },
    goBackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.btn {
  padding: 7.5px 30px;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-dialog__header {
  padding: 15px 0;

}
</style>
