import { request } from '../network/request.js'

export function orderList(query) {// 订单 列表
  return request({
    url: '/wechat/company/order/list',
    method: 'get',
    params: query,
  })
}

export function delorder(id) {// 订单 取消
  return request({
    url: '/wechat/company/order/' + id,
    method: 'delete',
  })
}

export function detailsorder(id) {// 订单 详情
  return request({
    url: '/wechat/company/order/' + id,
    method: 'get',
  })
}

export function companystore(data) {// 贮藏点 保存
  return request({
    url: '/wechat/company/store',
    method: 'post',
    data: data
  })
}

export function editcompanystore(id) {// 贮藏点 查看
  return request({
    url: '/wechat/company/store/' + id,
    method: 'get',
  })
}

export function delcompanystore(id) {// 贮藏点 删除
  return request({
    url: '/wechat/company/store/' + id,
    method: 'delete',
  })
}

export function querymoreInfo() {// 企业详细资料 查看
  return request({
    url: '/wechat/company/info',
    method: 'get',
  })
}

export function editmoreInfo(data) {// 企业详细资料 编辑
  return request({
    url: '/wechat/company/moreInfo',
    method: 'put',
    data: data
  })
}

export function balancelist(query) {// 流水 列表
  return request({
    url: '/wechat/company/balance/list',
    method: 'get',
    params: query,
  })
}

export function divisioncode(code) {// 区域代码 查询 根据区域代码
  return request({
    url: '/wechat/division/' + code,
    method: 'get',
  })
}

export function getDivisionList(data) {// 区域代码 下级列表
  return request({
    url: '/wechat/division/getDivisionList',
    method: 'post',
    data: data
  })
}
